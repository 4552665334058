'use client'

import Image from 'next/image'
import { useCallback } from 'react'
import cls from 'classnames'

import { AuthService } from '@/services'
import { dialog } from '@/utils/addon'

import { Link } from '../link'

export function NavbarComponent() {
  // __STATE<Next.14>

  // __FUNCTION's
  const handleLogout = useCallback(async () => {
    const { isConfirmed } = await dialog('Confirm to Signout?', { type: 'confirm', confirmLabel: 'confirm' })
    if (isConfirmed) {
      AuthService.signOut(() => {
        location.href = '/'
      })
    }
  }, [])

  // __RENDER
  return (
    <nav className='ui--navbar sticky top-0'>
      <div className='ui--navbar-container grid h-full gap-6'>
        <div className='ui--navbar-rows'>
          <Image
            className='logo opacity-80'
            src='/static/images/layers.png'
            alt='Layers Logo'
            width={50}
            height={50}
            quality={100}
            priority
          />
        </div>

        <div className='ui--navbar-rows'>
          <ul className='ul mirror use-ring'>
            <li className='li'>
              <Link className='btn' to='/lobby' key='.lobby'>
                <span className='icon bi bi-window-desktop'></span>
                <span className='capitalize'>index</span>
              </Link>
            </li>

            <li className='li'>
              <Link className='btn' to='/market' key='.market'>
                <span className='icon bi bi-shop'></span>
                <span className='capitalize'>market</span>
              </Link>
            </li>

            <li className='li'>
              <Link className='btn' to='/trans' key='.trans'>
                <span className='icon bi bi-journal-text'></span>
                <span className='capitalize'>transaction's</span>
              </Link>
            </li>

            <li className='li'>
              <Link className='btn' to='/records' key='.record'>
                <span className='icon bi bi-chat-dots'></span>
                <span className='text'>SMS Record's</span>
              </Link>
            </li>
          </ul>

          <ul className='ul mirror use-ring mt-7'>
            <li className='li'>
              <a className='btn router-link' key='.menu-1'>
                <span className='icon bi bi-hash'></span>
                <span className='capitalize'>menu 1</span>
              </a>
            </li>

            <li className='li'>
              <a className='btn router-link' key='.menu-2'>
                <span className='icon bi bi-hash'></span>
                <span className='capitalize'>menu 2</span>
              </a>
            </li>

            <li className='li'>
              <a className='btn router-link' key='.menu-3'>
                <span className='icon bi bi-hash'></span>
                <span className='capitalize'>menu 3</span>
              </a>
            </li>

            <li className='li'>
              <a className='btn router-link' key='.menu-4'>
                <span className='icon bi bi-hash'></span>
                <span className='capitalize'>menu 4</span>
              </a>
            </li>

            <li className='li'>
              <a className='btn router-link' key='.menu-5'>
                <span className='icon bi bi-hash'></span>
                <span className='capitalize'>menu 5</span>
              </a>
            </li>

            <li className='li'>
              <a className='btn router-link' key='.menu-6'>
                <span className='icon bi bi-hash'></span>
                <span className='capitalize'>menu 6</span>
              </a>
            </li>
          </ul>
        </div>

        <div className='ui--navbar-rows flex items-center justify-end gap-2'>
          <button className='btn btn-signout h-12 w-12 rounded-lg bg-neutral-400/5' type='button' disabled>
            <span className='icon bi bi-gear text-xl'></span>
          </button>

          <button
            className='btn btn-signout h-12 w-12 rounded-lg bg-neutral-400/5'
            type='button'
            onClick={handleLogout}
          >
            <span className='icon bi bi-power text-2xl'></span>
          </button>
        </div>
      </div>
    </nav>
  )
}
