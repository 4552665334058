export function GradientComponent() {
  // RENDER
  return (
    <div className='ui--app-gradient pointer-events-none'>
      <div className='fixed -bottom-[40%] -left-[25%] z-0 opacity-60'>
        <img src='/static/images/docs-left.png' />
      </div>

      <div className='fixed -right-[60%] -top-[80%] z-0 rotate-12 opacity-60 2xl:-right-[45%] 2xl:-top-[60%]'>
        <img src='/static/images/docs-right.png' />
      </div>
    </div>
  )
}
