'use client'

import { useMemo, type ReactNode } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReduxStore from '@/store'

import Dialog from './addon/dialog'
import Loader from './addon/loader'
import Modal from './addon/modal'
import Notice from './addon/notice'
import { LayoutContainer } from './layout'

type ProviderProps = { children: ReactNode }

export function AppProvider({ children }: ProviderProps) {
  // __STATE<Next.14>
  const queryClient = useMemo(() => new QueryClient(), [])

  // __RENDER
  return (
    <ReduxProvider store={ReduxStore}>
      <Loader />

      <QueryClientProvider client={queryClient}>
        <LayoutContainer>{children}</LayoutContainer>
      </QueryClientProvider>

      <Dialog />
      <Modal />
      <Notice />
    </ReduxProvider>
  )
}
