'use client'

import { usePathname } from 'next/navigation'
import { useMemo, type ReactNode } from 'react'
import cls from 'classnames'

import { GradientComponent } from '../gradient'
import { NavbarComponent } from './navbar'

type Props = { children: ReactNode }

export function LayoutContainer({ children }: Props) {
  // __STATE<Next.14>
  const pathname = usePathname()
  const protectedPage = useMemo(() => pathname !== '/', [pathname])

  // __RENDER
  return (
    <div className='ui--app-container bg-black'>
      <main className={cls('ui--router-view', { 'logged-in': protectedPage })}>
        {protectedPage && <NavbarComponent />}
        {children}
      </main>

      <GradientComponent />
    </div>
  )
}
